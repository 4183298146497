.solutionSwipper .swiper-button-next {
  bottom: 4%;
  top: unset;
  width: 2.5rem;
  height: 2.5rem;
}

.solutionSwipper .swiper-button-next::after {
  background-image: url("../../assets/swipperArrowRIght.svg");
  background-repeat: no-repeat;
  content: "";
  width: 100%;
  height: 100%;
}

.solutionSwipper .swiper-button-prev::after {
  background-image: url("../../assets/swipperArrowLeft.svg");
  background-repeat: no-repeat;
  content: "";
  width: 100%;
  height: 100%;
}

.solutionSwipper .swiper-button-prev {
  bottom: 4%;
  top: unset;
  right: 6%;
  left: unset;
  width: 2.5rem;
  height: 2.5rem;
}
.solutionSwipper .swiper {
  padding-bottom: 6rem;
}

@media screen and (max-width: 768px) {
  .solutionSwipper .swiper-button-prev {
    right: 18%;
  }
}


.explore-button {
  position: relative;
  padding-bottom: 4px; 
  color: #546176; 
  display: flex;
  align-items: center;
  transition: color 0.4s ease-in-out; 
}

.explore-button::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px; 
  width: 0; 
  background-color: #019875; 
  transition: width 0.2s ease-in-out; 
}

.explore-card:hover .explore-button span {
  color: #019875;
}

.explore-card:hover .explore-button svg {
  stroke: #019875; 
}
.explore-card .explore-button svg {
  stroke: #546176;
}

.explore-card:hover .explore-button::before {
  width: 100%; 
}
