.testimonialSwiper .swiper-button-next {
  width: 2.75rem;
  height: 2.75rem;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonialSwiper .swiper-button-next:hover {
  background-color: #009875;
}
.testimonialSwiper .swiper-button-next::after {
  background-image: url("../../assets/arrowRight.svg");
  content: "";
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.5rem;
}

.testimonialSwiper .swiper-button-prev {
  width: 2.75rem;
  height: 2.75rem;
  left: unset;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonialSwiper .swiper-button-prev::after {
  background-image: url("../../assets/arrowleft.svg");
  background-repeat: no-repeat;
  content: "";
  width: 1.5rem;
  height: 1.5rem;
}
.testimonialSwiper .swiper-button-prev:hover {
  background-color: #009875;
}

.swiper-pagination-bullet {
  background: rgba(255, 255, 255, 0.4); /* White with some opacity */
  width: 8px; /* Smaller size for inactive bullets */
  height: 8px;
  border-radius: 50%;
  transition: all 0.3s ease;
  margin: 0 4px; /* Small spacing between bullets */
}

/* Active pagination dot */
.swiper-pagination-bullet-active {
  background: #ffffff; /* Full white for active bullet */
  width: 24px; /* Wider size for active bullet */
  height: 8px; /* Same height, but wider */
  border-radius: 20px; /* Rounded rectangle shape */
  transition: all 0.3s ease; /* Smooth transition */
}
.testimonialSwiper .swiper-pagination {
  bottom: -40PX;
  left: 0;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .testimponialSwipper .swiper-button-prev {
    width: 1.5rem;
    height: 1.5rem;
  }
  .testimponialSwipper .swiper-button-prev::after {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
  .testimponialSwipper .swiper-button-next {
    width: 1.5rem;
    height: 1.5rem;
  }
  .testimponialSwipper .swiper-button-next::after {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}
