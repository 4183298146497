@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  max-width: 90rem;
  width: 100%;
  margin: 0 auto;
  padding-left: 10rem;
  padding-right: 10rem;
}
@media screen and (max-width: 1024px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
* {
  scroll-behavior: smooth;
}
.transition-max-height {
  transition: all 0.4s ease;
}
